//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import lodash from 'lodash';

import { PlaceModel } from '@/modules/place/place-model';
const { fields } = PlaceModel;

export default {
  name: 'app-place-view-page',
  props: ['id'],

  async created() {
    await this.doFind(this.id);
    this.center.lat = parseFloat(this.record.lat);
    this.center.lng = parseFloat(this.record.lng);
    this.markers.push({
      position: { lat: this.center.lat, lng: this.center.lng }
    })
  },
  data() {
    return {
      model: null,
      center: { lat: null, lng: null },
      markers: [],
    };
  },
  computed: {
    ...mapGetters({
      record: 'place/view/record',
      loading: 'place/view/loading',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    isRTL() {
      return this.currentLanguageCode == 'ar';
    },
  },
  
  methods: {
    ...mapActions({
      doFind: 'place/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(record, fieldName) {
      const val = PlaceModel.presenter(record, fieldName);
      if (fieldName == 'strokeColor' || fieldName == 'fillColor') {
        return val.includes('#') ? val : `#${val}`
      }
      return val ? val : lodash.isNumber(val) ? 0 : 'ـــ'
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = row[fieldName]
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.currentLanguageCode == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
      // return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    // updateCoordinates(location) {
    //   this.center = {
    //     lat: location.latLng.lat(),
    //     lng: location.latLng.lng(),
    //   };
    // },
  },
};
